<template>
  <main class="Packages">
    <TheSubHeader
      title="Pacotes"
      icon="package"
      type="filter"
      organizer-gap="1rem"
      organizer-columns="1fr 235px 1fr 1fr 1fr 110px"
    >
      <template #filter>
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="busca por pacote "
          label="busca por pacote"
          no-options-text="digite e pressione Enter"
          no-results-text="digite e pressione Enter"
          placeholder="ex.: QL8464121646BR"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          @input="updateTags()"
        />
        <BaseDatePicker
          v-model="formattedDateRange"
          label="filtrar por data"
        />
        <BaseTreeSelect
          v-model="filters.seller"
          name="sellers"
          label="sellers"
          placeholder="selecione"
          multiple
          :min-search-length="2"
          suggestions="searchSellers"
          @input="updateTags()"
        />
        <BaseTreeSelect
          v-model="filters.store"
          name="lojista"
          label="lojista"
          placeholder="selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="updateTags()"
        />
        <BaseTreeSelect
          v-model="filters.driver"
          name="motorista"
          label="motorista"
          placeholder="selecione"
          multiple
          suggestions="searchDrivers"
          @input="updateTags()"
        />
        <BaseButton
          label="filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          :disabled="isSearchable"
          text-color="white"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Packages__Content">
      <!-- <BaseButton
        v-if="packages.data.content.length > 0 && searched"
        class="Packages__ExportBtn"
        label="baixar resultados"
        theme="icon-right"
        icon="download"
        @click="exportPackagesResult"
      /> -->
      <div
        v-if="searched"
        class="Packages__Table"
      >
        <DataTable
          class="Packages__Table"
          :header="packages.data.headers"
          :table="packages.data.content"
          is-sticky
          @see="openPackageDetail"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
    
    <PackageDetail ref="PackageDetail" />
  </main>
</template>

<script>
import { packages as packagesApi } from '@/api'
import { mapActions, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { DataTable, Pagination, FiltersTag } from '@/components/molecules'
import { BaseDatePicker, BaseTreeSelect, BaseButton } from '@/components/atoms'
import PackageDetail from './PackageDetail.vue'

export default {
  name: 'Packages',
  components: {
    TheSubHeader,
    DataTable,
    BaseDatePicker,
    BaseTreeSelect,
    BaseButton,
    Pagination,
    PackageDetail,
    FiltersTag
  },
  data() {
    return {
      searched: false,
      allTags: [],
      filters: {
        query: [],
        dateRange: {
          init: null,
          end: null,
        },
        seller: [],
        store: [],
        driver: []
      }
    }
  },
  computed: {
    ...mapState({
      packages: state => state.packages.packages,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    dateRangeInit() {
      const days = 15
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    isSearchable() {
      let f = this.filters
      return !(
        f.query.length > 0 ||
        f.dateRange.init ||
        f.dateRange.end ||
        f.seller.length > 0 ||
        f.store.length > 0 ||
        f.driver.length > 0
      )
    },

    searchParams() {
      return {
        query: this.filters.query,
        date_start: this.filters.dateRange.init,
        date_end: this.filters.dateRange.end,
        seller: this.filters.seller,
        store: this.filters.store,
        driver: this.filters.driver,
      }
    }
  },
  mounted() {
    
  },
  destroyed() {

  },
  methods: {
    ...mapActions({
      fetchPackages: 'packages/fetchPackages',
      clearPackages: 'packages/clearPackages',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
    }),

    reset() {
      this.clearPackages()
      this.searched = false
    },

    updateTags(){
      this.allTags = [
        ...this.filters.query,
        ...this.filters.seller,
        ...this.filters.store,
        ...this.filters.driver
      ]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      const filters = ['query', 'seller', 'store', 'driver']
      filters.forEach(f => {
        this.filters[f] = this.filters[f].filter((tag) => tag.name !== name)
      })
      if (this.allTags.length > 0) {
        this.search()
      } else {
        this.clearPackages()
      }
    },

    clearAllTags(){
      this.allTags = []
      const filters = ['query', 'seller', 'store', 'driver']
      filters.forEach(f => {
        this.filters[f] = []
      })
      this.reset()
    },

    search() {
      this.searched = true
      this.updateTags()
      this.setLoading(true)
      
      const params = {
        obj: {
          'query': this.searchParams.query,
          'date_start': this.searchParams.date_start,
          'date_end': this.searchParams.date_end,
          'filter[seller][seller_id]': this.searchParams.seller,
          'filter[driver][driver_id]': this.searchParams.driver,
          'filter[store][store_id]': this.searchParams.store,
          'filter[orderInfo][order_type_id][]': '__const:orderTypes__collect'
        },
        paginate: this.paginate,
        page: this.page
      }
      this.fetchPackages(params).then(() => this.setPagination(this.packages))
    },

    resetSearch() {
      this.clearPagination(this.paginate).then(() => this.search())
    },

    openPackageDetail(data) {
      this.$refs.PackageDetail.open(data.id)
    },

    exportPackagesResult() {
      this.setLoading(true)

      let params = this.searchParams

      let data = {
        nameReport: 'pacotes',
        date_start: params.date_start,
        date_end: params.date_end,
        driver_id: params.driver.map(d => d.id),
        code: params.query.map(q => q.id),
        seller_id: params.seller.map(s => s.id),
        store_id: params.store.map(s => s.id),
      }

      packagesApi.exportPackagesResult(data, (res) => {
        let id = res.id

        this.$toast.info('gerando planilha dos resultados de pacotes...', {
          id: `export_real_time_pacotes_${id}`,
          icon: <Icon name="loading" color="white" />,
          timeout: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
          position: 'bottom-center',
          onClose: () => {
            this.$echo.leave(`export_real_time_pacotes.${id}`)
          }
        })

        this.$echo.channel(`export_real_time_pacotes.${id}`).listen('.download', () => {
          this.$toast.dismiss(`export_real_time_pacotes_${id}`)          
          this.downloadPackagesResult(id)
        })

      }, (e) => e, () => {
        this.setLoading(false)
      })
    },

    async downloadPackagesResult(id) {
      this.setLoading(true)
      await packagesApi.downloadPackagesResult(id, () => {
        this.$toast.success('download iniciado', {
          position: 'bottom-center'
        })
      }, () => {
        this.$toast.error('ocorreu um erro ao gerar o arquivo, por favor tente novamente', {
          position: 'bottom-center'
        })
      }, () => this.setLoading(false))
    },
  }
}
</script>

<style lang="scss" scoped>
.Packages {
  &__Content {
    position: relative;
    display: block;
    width: calc(100% - 2rem);
    max-width: 1180px;
    margin: 0 auto 2rem;
  }
  &__ExportBtn {
    width: 200px;
  }
  &__Table {
    margin-top: 1rem;
  }
}
</style>

<template>
  <main class="PackageDetail">
    <Drawer
      ref="Drawer"
      @close="closed"
    >
      <div class="PackageDetail__Content">
        <div class="PackageDetail__Header">
          <div class="PackageDetail__Header__Item">
            <span>pacote</span>
            <strong>{{ packageDetail.code }}</strong>
          </div>
          <div class="PackageDetail__Header__Item">
            <span>última atualização</span>
            <span>{{ lastUpdated }}</span>
          </div>
        </div>
        <div class="PackageDetail__Status">
          status
          <div
            class="PackageDetail__Status__Pill"
            :class="`PackageDetail__Status__Pill--${lastStatus.theme}`"
          >
            {{ lastStatus.name }}
          </div>
        </div>
        <div class="PackageDetail__Info">
          <p v-if="packageDetail.dimensions" class="PackageDetail__Info--dimensions">
            <strong>dimensões:</strong> {{ packageDetail.dimensions }}
          </p>
          <p v-if="packageDetail.weight" class="PackageDetail__Info--weight">
            <strong>peso:</strong> {{ packageDetail.weight }} kg
          </p>

          <p v-if="packageDetail.order_products_order" class="PackageDetail__Info--order_products_order">
            <strong>pedido:</strong> {{ packageDetail.order_products_order }}
          </p>
          <p v-if="packageDetail.driver_name" class="PackageDetail__Info--driver_name">
            <strong>motorista:</strong> {{ packageDetail.driver_name }}
          </p>
          <p v-if="packageDetail.store_name" class="PackageDetail__Info--store_name">
            <strong>lojista:</strong> {{ packageDetail.store_name }}
          </p>
          <p v-if="packageDetail.reception_user_name" class="PackageDetail__Info--reception_user_name">
            <strong>recebedor:</strong> {{ packageDetail.reception_user_name }}
          </p>
          <p v-if="packageDetail.reception_id" class="PackageDetail__Info--reception_id">
            <strong>ID recebimento:</strong> {{ packageDetail.reception_id }}
          </p>
          <p v-if="packageDetail.transporter_slug" class="PackageDetail__Info--transporter_slug">
            <strong>transportadora:</strong> {{ packageDetail.transporter_slug }}
          </p>

          <p v-if="packageDetail.seller_name" class="PackageDetail__Info--seller_name">
            <strong>seller:</strong> {{ packageDetail.seller_name }}
          </p>
          <p v-for="phone in packageDetail.seller_phones" :key="phone" class="PackageDetail__Info--phone">
            <strong>telefone:</strong> {{ phone }}
          </p>
        </div>
        <BaseTimeline class="PackageDetail__Timeline" :items="timeline" />
      </div>
    </Drawer>
  </main>
</template>

<script>
import Mask from '@/plugins/mask/Mask.js'
import { packages } from '@/api'
import { mapActions } from 'vuex'
import { Drawer } from '@/components/molecules'
import { BaseTimeline } from '@/components/atoms'
export default {
  name: 'PackageDetail',
  components: {
    Drawer,
    BaseTimeline
  },
  data() {
    return {
      packageDetail: {}
    }
  },
  computed: {
    lastStatus() {
      return this.status(this.packageDetail?.status_history?.[0])
    },
    lastUpdated() {
      return Mask.millisecondsToDateHour(this.packageDetail?.status_history?.[0]?.date)
    },
    timeline() {
      let items = this.packageDetail?.status_history?.map(s => this.status(s))
      let themedItems = items?.map((t, i) => {
        return {
          ...t,
          theme: (
            items[0].theme !== 'success' &&
            items[0].theme !== 'neutral'
              ? `${items[0].theme}`
              : `${t.theme}`
          ) + `${i > 0 ? '-softer' : ''}`
        }
      })
      return themedItems
    }
  },
  mounted() {
    
  },
  destroyed() {

  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    status(status_data) {
      let status_list = {
        analysis: {
          name: 'em análise',
          icon: 'pending'
        },
        canceled: {
          name: 'cancelado',
          icon: 'close-circle'
        },
        collected_out_of_spot: {
          name: 'coletado fora do ponto',
          icon: 'truck-ds'
        },
        collected: {
          name: 'coletado',
          icon: 'truck-ds'
        },
        dispatched: {
          name: 'despachado',
          icon: 'rocket'
        },
        dropoff_to_hub: {
          name: 'em transferência para o hub',
          icon: 'truck-ds-alt'
        },
        lost: {
          name: 'extraviado',
          icon: 'dashboard-theft'
        },
        not_bleeped: {
          name: 'não bipado',
          icon: 'barcode'
        },
        pending: {
          name: 'pendente',
          icon: 'pending'
        },
        received_dropoff: {
          name: 'recebido no dropoff',
          icon: 'stock'
        },
        received: {
          name: 'recebido',
          icon: 'stock'
        },
      }
      let status_themes = {
        dispatched: 'success',
        not_bleeped: 'neutral',
        canceled: 'alert'
      }
      let status = status_list[status_data?.status] || {}
      if (status.name) {
        status.theme = status_themes[status_data?.status] || 'primary'
        status.date = Mask.millisecondsToDateHour(status_data?.date)
      }
      return status
    },

    reset() {
      this.packageDetail = {}
    },

    open(id) {
      this.setLoading(true)
      packages.fetchPackageDetail(id, (res) => {
        this.packageDetail = res
        this.$refs.Drawer.open()
      }, (e) => e, () => this.setLoading(false))
    },

    closed() {
      this.reset()
    },

  }
}
</script>

<style lang="scss" scoped>
.PackageDetail {
  &__Content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    position: relative;
  }
  &__Header {
    display: flex;
    width: 100%;
    @media #{$mobile-view} {
      margin-top: 1rem;
    }

    &__Item {
      display: flex;
      flex-direction: column;
      width: 65%;
      font-size: 14px;

      strong {
        font-size: 18px;
      }
      
      &:last-of-type {
        text-align: right;
        width: 35%
      }
    }
  }
  &__Status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__Pill {
      display: flex;
      align-items: center;
      justify-self: center;
      height: 32px;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      padding: 0 16px;
      border-radius: 32px;
      &--primary { background: $color-primary-base }
      &--alert { background: $color-alert-base }
      &--success { background: $color-success-base }
      &--neutral { background: $color-neutral-base }
    }
  }
  &__Info {
    line-height: 150%;
    &--order_products_order,
    &--seller_name {
      margin-top: 1rem;
    }
  }
  &__Timeline {
    padding: 1rem 0 1rem 1rem;
  }
}
</style>
